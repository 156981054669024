const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/';
} else {
    baseUrl = '/';
}

export default {
    auth : {
        login          : baseUrl + 'auth/login/',
        logout         : baseUrl + 'auth/logout/',
        status         : baseUrl + 'auth/status/',
        changePassword : baseUrl + 'auth/change-password/'
    },

    admin : {
        blog : {
            addEdit : baseUrl + 'event/blog/add-edit/',
            delete  : baseUrl + 'event/blog/delete/',
            list    : baseUrl + 'event/blog/list/'
        },
        solutions : {
            addEdit : baseUrl + 'event/solutions/create/',
            delete  : baseUrl + 'event/solutions/delete/',
            list    : baseUrl + 'event/solutions/list/'
        },
        blogCategory : {
            vueSelect : baseUrl + 'event/blog-category/vue-select/',
            addEdit   : baseUrl + 'event/blog-category/add-edit/',
            delete    : baseUrl + 'event/blog-category/delete/',
            list      : baseUrl + 'event/blog-category/list/'
        },
        career : {
            addEdit       : baseUrl + 'auth/career/add-edit/',
            delete        : baseUrl + 'auth/career/delete/',
            list          : baseUrl + 'auth/career/list/',
            applicantList : baseUrl + 'auth/career-application/list/'
        },

        partner : {
            addEdit    : baseUrl + 'event/partners/create/',
            delete     : baseUrl + 'event/partners/delete/',
            bannerList : baseUrl + 'event/partner-banner/list/',
            list       : baseUrl + 'event/partners/list/'
        },

        training : {
            enquiryList : baseUrl + 'event/enquiry/list/',
            addEdit     : baseUrl + 'auth/training/add-edit/',
            delete      : baseUrl + 'auth/training/delete/',
            list        : baseUrl + 'auth/training/list/',
            schedule    : {
                addEdit : baseUrl + 'auth/training/schedule/add-edit/',
                delete  : baseUrl + 'auth/training/schedule/delete/',
                list    : baseUrl + 'auth/training/schedule/list/'
            }
        },

        address : {
            addEdit : baseUrl + 'auth/address/add-edit/',
            delete  : baseUrl + 'auth/address/delete/',
            list    : baseUrl + 'auth/address/list/'
        },

        report : {
            register  : baseUrl + 'auth/report/register/list/',
            contactUS : baseUrl + 'auth/report/contact-us/list/'
        },

        event : {
            videoGallery : {
                addEdit : baseUrl + 'event/media/video-gallery-add-edit/',
                delete  : baseUrl + 'event/media/video-gallery-delete/',
                list    : baseUrl + 'event/media/video-gallery-list/'
            },
            gallery : {
                addEdit      : baseUrl + 'event/media/gallery-add-edit/',
                delete       : baseUrl + 'event/media/gallery-delete/',
                list         : baseUrl + 'event/media/gallery-list/',
                details      : baseUrl + 'event/media/gallery-detail/',
                imageAddEdit : baseUrl + 'event/media/gallery-items-add-edit/',
                imageList    : baseUrl + 'event/media/gallery-items-list/',
                imageDelete  : baseUrl + 'event/media/gallery-items-delete/'
            },
            service : {
                addEdit                   : baseUrl + 'event/service/add-edit/',
                delete                    : baseUrl + 'event/service/delete/',
                list                      : baseUrl + 'event/service/list/',
                info                      : baseUrl + 'event/service/info/',
                activateDeactivate        : baseUrl + 'event/service/activate-deactivate/',
                activateDeactivateWebsite : baseUrl + 'event/service/website-enable-disable/'
            },

            training : {
                addEdit                   : baseUrl + 'event/training/add-edit/',
                delete                    : baseUrl + 'event/training/delete/',
                list                      : baseUrl + 'event/training/list/',
                info                      : baseUrl + 'event/training/info/',
                activateDeactivate        : baseUrl + 'event/training/activate-deactivate/',
                activateDeactivateWebsite : baseUrl + 'event/training/website-enable-disable/',
                schedule                  : {
                    addEdit            : baseUrl + 'event/training/schedule/add-edit/',
                    delete             : baseUrl + 'event/training/schedule/delete/',
                    list               : baseUrl + 'event/training/schedule/list/',
                    activateDeactivate : baseUrl + 'event/training/schedule/activate-deactivate/',
                    calendar           : baseUrl + 'event/training/schedule/calendar/'
                },

                coupon : {
                    addEdit : baseUrl + 'event/training/coupon/add-edit/',
                    delete  : baseUrl + 'event/training/coupon/delete/',
                    list    : baseUrl + 'event/training/coupon/list/'
                }
            },

            banner : {
                addPartnerBannerEdit : baseUrl + 'event/partner-banner/add-edit/',
                addEdit              : baseUrl + 'event/banner/add-edit/',
                delete               : baseUrl + 'event/banner/delete/',
                list                 : baseUrl + 'event/banner/list/'
            },

            testimonials : {
                addEdit            : baseUrl + 'event/testimonials/add-edit/',
                delete             : baseUrl + 'event/testimonials/delete/',
                list               : baseUrl + 'event/testimonials/list/',
                activateDeactivate : baseUrl + 'event/testimonials/activate-deactivate/'
            },
            about_us : {
                addEdit : baseUrl + 'event/about-us/create/',
                delete  : baseUrl + 'event/about-us/delete/',
                list    : baseUrl + 'event/about-us/list/'
            },
            aboutUsProfile : {
                addEdit : baseUrl + 'event/about-us-profiles/create/',
                delete  : baseUrl + 'event/about-us-profiles/delete/',
                list    : baseUrl + 'event/about-us-profiles/list/'
            },
            management : {
                addEdit : baseUrl + 'event/management/create/',
                delete  : baseUrl + 'event/management/delete/',
                list    : baseUrl + 'event/management/list/'
            }
        }
    },

    transactions : {
        initiate        : baseUrl + 'transaction/payment/initialization/',
        transactionList : baseUrl + 'transaction/payment/list/'
    },

    backup : {
        register    : baseUrl + 'auth/report/register-backup/',
        transaction : baseUrl + 'auth/report/transactions-backup/',
        contactUs   : baseUrl + 'auth/report/contactus-backup/'
    }
};
