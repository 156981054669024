import guards from './guards';
const Layout = () => import('../layouts/MainLayout');
const Home = () => import('../views/Home');

export default {
    path        : '/',
    name        : 'DashboardLayout',
    redirect    : '/',
    component   : Layout,
    props       : true,
    beforeEnter : guards.loggedInGuard,
    children    : [
        {
            path      : '/',
            name      : 'Home',
            component : Home
        },
        {
            path      : '/partner/',
            name      : 'partner',
            component : () => import('../views/admin/partner/Partner')
        },
        {
            path      : '/career/',
            name      : 'career',
            component : () => import('../views/admin/career/Career')
        },
        {
            path      : '/career-applicants/',
            name      : 'CareerApplicants',
            component : () => import('../views/admin/career/CareerApplyRequests')
        },
        {
            path      : '/training-enquiry/',
            name      : 'TrainingEnquiry',
            component : () => import('../views/admin/training/TrainingEnquiry')
        },
        {
            path      : '/training/:id/schedule/',
            name      : 'training-schedule',
            component : () => import('../views/admin/training/schedule/Schedule')
        },
        {
            path      : '/report/register/list/',
            name      : 'report-register-list',
            component : () => import('../views/admin/report-list/registration/Registration')
        },
        {
            path      : '/report/contact/list/',
            name      : 'report-contact-list',
            component : () => import('../views/admin/report-list/contact-message/ContactMessage')
        },
        {
            path      : '/change-password/',
            name      : 'change-password',
            component : () => import('../views/authentication/change-password/ChangePassword')
        },
        {
            path      : '/event/service/',
            name      : 'event-service',
            component : () => import('../views/admin/event/service/Service')
        },
        {
            path      : '/training/',
            name      : 'event-training',
            component : () => import('../views/admin/event/event-training/EventTraining')
        },
        {
            path      : '/training/add/',
            name      : 'event-training-add',
            component : () => import('../views/admin/event/event-training/AddTraining')
        },
        {
            path      : '/training/:id/details/',
            name      : 'event-training-details',
            component : () => import('../views/admin/event/event-training/EventTrainingDetails')
        },
        {
            path      : '/training/schedule/calendar/',
            name      : 'event-training-calendar',
            component : () => import('../views/admin/event/event-training/training-schedule/ScheduleCalendar')
        },
        {
            path      : '/event/banner/',
            name      : 'event-banner',
            component : () => import('../views/admin/event/banner/Banner')
        },
        {
            path      : '/gallery-events/',
            name      : 'GalleryEvents',
            component : () => import(/* webpackChunkName: "home" */'../views/admin/event/gallery/GalleryEventListing')
        },
        {
            path      : '/gallery-events/:id/details/',
            name      : 'GalleryEventsDetails',
            component : () => import(/* webpackChunkName: "home" */'../views/admin/event/gallery/DetailsPage')
        },
        {
            path      : '/video-gallery-events/',
            name      : 'VideoGalleryEvents',
            component : () => import(/* webpackChunkName: "home" */'../views/admin/event/video-gallery/Videos')
        },
        {
            path      : '/transaction/:type/',
            name      : 'transactions',
            component : () => import('../views/admin/transaction/TransactionList')
        },
        {
            path      : '/testimonial/',
            name      : 'testimonial',
            component : () => import('../views/admin/event/testimonials/Testimonials')
        },
        {
            path      : '/address/',
            name      : 'address',
            component : () => import('../views/admin/address/Address')
        },
        {
            path      : '/solutions/',
            name      : 'solutions',
            component : () => import('../views/admin/solutions/Solutions')
        },
        {
            path      : '/blog/',
            name      : 'blog',
            component : () => import('../views/admin/blog/Blog')
        },
        {
            path      : '/blog-category/',
            name      : 'blog',
            component : () => import('../views/admin/blog/blog-category/BlogCategory')
        },
        {
            path      : '/about-us/',
            name      : 'about-us-list',
            component : () => import('../views/admin/about-us/AboutUs')
        },
        {
            path      : '/about-us-profile/',
            name      : 'about-us-profile-list',
            component : () => import('../views/admin/about-us/about-us-profile/AboutUsProfile')
        },
        {
            path      : '/management/',
            name      : 'management-list',
            component : () => import('../views/admin/management/Management')
        }
    ]
};
