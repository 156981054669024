<template>
    <div v-if="imageValidationRequired" class="text-center bg-1 p-2 mt-1 mb-1">
        <p class="mb-1">Kindly ensure that image have in proper dimension. Height in-between
            <b>{{ height1 }}px-{{ height2 }}px</b> and Width in-between
            <b>{{ width1 }}px-{{ width2 }}px</b>
        </p>
    </div>
    <div v-else></div>
</template>

<script>
export default {
    name  : 'ImageInfo',
    props : {
        height : { type : Number, default : 500 },
        width  : { type : Number, width : 1000 }
    },
    data () {
        return {
            imageValidationRequired : false,
            height1                 : 0,
            height2                 : 0,
            width1                  : 0,
            width2                  : 0
        };
    },
    mounted () {
        this.setDimensions();
    },
    watch : {
        height : {
            handler () {
                this.setDimensions();
            }
        },
        width : {
            handler () {
                this.setDimensions();
            }
        }
    },
    methods : {
        setDimensions () {
            this.height1 = this.height - 20;
            this.height2 = this.height + 20;
            this.width1 = this.width - 20;
            this.width2 = this.width + 20;
        }
    }
};
</script>

<style scoped>

</style>
